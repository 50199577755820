@use 'theme';
@use 'fonts';
@use 'borders';
@use 'transitions';

.inputBorder {
  align-items: center;
  background-color: theme.palette('white');
  border: borders.get();
  border-radius: borders.radius('md');
  display: flex;
  &.hasError {
    border: borders.get($color: theme.palette('error'));
  }
  &.disabled input {
    opacity: 0.6;
    background-color: theme.palette('white');
  }
}

.filled {
  border-color: theme.get('darkDividerColor');
}

.inputRaw {
  width: 100%;
  border: none;
  background-color: transparent;
}

.inputWrapper {
  align-items: center;
  display: flex;
  flex: 1;
  &.isMultiline {
    height: auto;
    min-height: 80px;
  }
}

.inputWrapperWithoutAdortment {
  padding-right: theme.spacing(3);
}

.input {
  appearance: textfield;
  border: 0;
  box-shadow: none;
  color: theme.get('textColor');
  padding: theme.spacing(0);
  flex: 1;
  font-family: Lato;
  height: 100%;
  outline: none;
  width: 100%;
  &::placeholder {
    color: rgba(theme.get('textColorRgb'), 0.6);
  }
  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px white inset !important;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    -webkit-text-fill-color: theme.get('textColor') !important;
  }
  &:focus:not(:read-only)::placeholder {
    opacity: 0;
  }
  &.isMultiline {
    padding-top: theme.spacing(2);
    resize: none;
  }
}

.focusPlaceholder {
  &:focus:not(:read-only)::placeholder {
    opacity: 1;
  }
}

.inputFilled {
  background-color: theme.palette('white');
  &.filled {
    border: borders.get($color: theme.get('darkDividerColor'));
  }

  & .input {
    font-weight: fonts.weight('bold');
    &::placeholder {
      font-weight: fonts.weight('regular');
    }
  }
}

.inputContent {
  flex: 1;
  height: 100%;
  width: 100%;
  padding-right: theme.spacing(0.5);
  position: relative;
  display: flex;
}

.sm {
  height: 30px;
  padding-left: theme.spacing(1);
}

.md {
  height: 40px;
  padding: theme.spacing(0, 2);
}

.lg {
  height: 56px;
  padding-left: theme.spacing(3);
}

.readOnly {
  cursor: default;
}

.label {
  margin-bottom: theme.spacing(2);
}

.floatingPlaceholder {
  align-items: center;
  color: rgba(theme.get('textColorRgb'), 0.6);
  display: flex;
  height: 100%;
  font-size: fonts.size('xSmall');
  left: -100%;
  pointer-events: none;
  padding: theme.spacing(0, 1, 0, 0.5);
  position: relative;
  transform: translate(100%, 0);
  transition: transitions.get(('transform', 'left')),
}

.floatingPlaceholderMoved {
  transform: translate(0, 0);
  left: 0;
  font-size: fonts.size('micro');
}

.suffix {
  color: rgba(theme.get('textColorRgb'), 0.6);
  display: flex;
  font-size: fonts.size('micro');
  max-width: 55%;
  height: 100%;
  padding: theme.spacing(0, 1, 0, 0.5);
  pointer-events: none;
  align-items: var(--suffixPosition);
}

.message {
  font-size: fonts.size('xSmall');
  margin: theme.spacing(1, 0);
}

.errorMessage {
  color: theme.palette('error');
}

.required {
  margin-left: theme.spacing(0.25);
}

.clearIcon {
  stroke: theme.get('textColor');
  width: 12px;
  cursor: pointer;
}
